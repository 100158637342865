import LocalStorageBackend from 'i18next-localstorage-backend';
import gazetteer, { defaultLocale as appDefaultLocale } from '@bridebook/toolbox/src/gazetteer';
import { COOKIE_I18N_LANG } from 'app-shared/lib/i18n/cookies';
import { languageTags } from 'lib/i18n/constants';
import nextI18NextConfig from './next-i18next.base.config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Cookies = require('universal-cookie');

const isBrowser = typeof window !== 'undefined';

/**
 * We override the LocalStorageBackend.read to use the user locale specified in the cookie instead of the browser.
 * If there si no cached data under the key with the user locale, we return null to force the i18n to fetch the
 * translations from the server and than save them in the local storage.
 */
LocalStorageBackend.prototype.read = function (language, namespace, callback) {
  // Get COOKIE_I18N_LANG cookie value
  const userLocale = getLocale();
  const nowMS = Date.now();

  if (!this.storage.store) {
    return callback(null, null);
  }

  // Read data from local storage using value from COOKIE_I18N_LANG cookie.
  let local = this.storage.getItem(`${this.options.prefix}${userLocale}-${namespace}`);

  if (local) {
    local = JSON.parse(local);
    const version = this.getVersion(userLocale);
    if (
      // expiration field is mandatory, and should not be expired
      local.i18nStamp &&
      local.i18nStamp + this.options.expirationTime > nowMS &&
      // there should be no language version set, or if it is, it should match the one in translation
      version === local.i18nVersion
    ) {
      const i18nStamp = local.i18nStamp;
      delete local.i18nVersion;
      delete local.i18nStamp;
      return callback(null, local, i18nStamp);
    }
  }

  return callback(null, null);
};

/**
 * We override the LocalStorageBackend.save to use the user locale specified in the cookie instead of the browser
 * locale. This is necessary to avoid caching translations for the wrong language and saving unnecessary data in
 * local storage.
 */
LocalStorageBackend.prototype.save = function (language, namespace, data) {
  // Get COOKIE_I18N_LANG cookie value
  const userLocale = getLocale();

  // If userLocale is different than language, do not save the translation.
  if (userLocale !== language) {
    return;
  }

  if (this.storage.store) {
    data.i18nStamp = Date.now();

    // language version (if set)
    const version = this.getVersion(userLocale);
    if (version) {
      data.i18nVersion = version;
    }

    // save
    this.storage.setItem(`${this.options.prefix}${userLocale}-${namespace}`, JSON.stringify(data));
  }
};

module.exports = {
  ...nextI18NextConfig,
  i18n: {
    defaultLocale: appDefaultLocale,
  },
  backend: {
    /**
     * We use the LocalStorageBackend to cache translations in the browser, if no translations are cached
     * we get them from the server.
     */
    backends: isBrowser ? [LocalStorageBackend, ...nextI18NextConfig.backend.backends] : [],
    backendOptions: isBrowser
      ? [
          {
            defaultVersion: process.env.NEXT_PUBLIC_PLATFORM_VERSION,
            // 7 days expiration time as on each version change translations are reloaded
            expirationTime: 7 * 24 * 60 * 60 * 1000,
          },
          {
            loadPath: (locale, namespace) => {
              const languageTag = languageTags[locale] || locale;
              return `/locales/${languageTag}/${namespace}.json?v=${process.env.NEXT_PUBLIC_PLATFORM_VERSION}`;
            },
          },
        ]
      : [],
  },
  fallbackLng: false,
  /**
   * The only way I've found to prevent i18n next to attempt to fetch language files using url locale, which
   * would return 404 in case of bb-global locales (like ca-en for example).
   */
  supportedLngs: [
    ...gazetteer.getMarkets({ monetized: true }).map((market) => market.locale),
    appDefaultLocale,
  ],
  onPreInitI18next: (i18n) => {
    /**
     * Called by appWithTranslation when initializing the i18n instance for the first time
     * We override the lng value with the user locale specified in the cookie, if it exists.
     * This makes the translations available earlier and prevents flickering.
     */
    i18n.options.lng = getLocale();
  },
};

const getLocale = () => {
  const cookies = isBrowser ? new Cookies.default() : new Cookies();
  const userLocale = cookies.get(COOKIE_I18N_LANG);
  let defaultLocale = appDefaultLocale;

  if (isBrowser) {
    try {
      // Attempt to set default value based on url.
      defaultLocale = gazetteer.getMarketByURL(window.location.href, appDefaultLocale).locale;

      /** Signup page is the only logout page that uses client side translations, but we can't use value from COOKIE_I18N_LANG for it, because it would always return COOKIE_I18N_LANG-value translations, even if user is in "en-GB" market and has "uk" locale in url. */
      if (window.location.pathname.includes('/signup')) {
        return defaultLocale;
      }
    } catch (e) {
      /** do nothing */
    }
  }

  return userLocale || defaultLocale;
};
